/**
 * 接口域名的管理
 */
let base = {
  sdkAppId: 1600043319,
  secretKey: 'c38eefb921b08a15dcd6e39f0df28d882fc9b895beccbe9731efa848ad5fe832',
  IM_prefix: 'user', //IM用户id前缀（user+用户id）
  IM_systemId: 'C2Csystem',//系统消息会话ID
  chatImg: 'https://test-i.seestech.com:1123', //用于判断聊天是否为图片
  agreementUrl: 'https://ihospital.seestech.com:1123/iotss/webApp/html/', //协议地址-正式
  prescription_stamp: "https://ihospital.seestech.com:1123/iotss/common/signature/seal.png",//处方笺印章
  imgUrl: 'https://ihospital.seestech.com:1123/iotss/static',
	// 生产
  baseUrl: 'https://ihospital.seestech.com',
	wsUrl: 'wss://ihospital.seestech.com/websocket',
  loginWsUrl: 'wss://ihospital.seestech.com/websocket', // 用于app扫码登陆
  pageUrl: "https://www.ilovehealth.com.cn", // 此url用于用户微信扫码"登录"发布生产时需注意更换
  DOCTOR_PRODUCT: 'https://www.ilovehealth.com.cn/clinic/doctor/#/login', // 医生端生产环境地址
  PHYSICAN_PRODUCT: 'https://www.ilovehealth.com.cn/clinic/physican/#/login', // 药师端生产环境地址
};
export default base;
